@tailwind base;
@tailwind components;
@tailwind utilities;

/* In your global CSS file, e.g., styles.css */
@layer utilities {
    ::selection {
      @apply bg-mhghLightGreen-300 text-black; /* Use Tailwind classes */
    }
    ::-moz-selection {
      @apply bg-mhghLightGreen-300 text-black; /* Use Tailwind classes */
    }
  }